.home-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
  .box .h3 {
    width: 100%;
    display: flex;
    span {
      flex-grow: 1;
    }
  }

  .chartTitle {
    position: relative;
    top: 16px;
    right: 16px;
    font-size: 1.5vw;
    z-index: 1;
    font-weight: bold;
    text-align: right;
    @media (orientation: landscape) {
      font-size: 0.75vw;
    }
  }
  &.one-item {
    .box {
      width: 100%;
      height: 50%;
      max-height: 50%;
      @media (orientation: landscape) {
        height: 100%;
        max-height: 100%;
      }
    }
  }

  &.two-items {
    .box {
      margin-bottom: 30px;
    }
    @media (orientation: landscape) {
      // flex-direction: column;
      .chart {
        height: 50%;
      }
      .box {
        width: calc(50% - 15px);
        height: 100%;
        min-height: 100%;
        margin-bottom: 0px;
      }
    }
  }

  &.three-items {
    @media (orientation: portrait) {
      display: block;
    }
    .box {
      @media (orientation: portrait) {
        margin-top: 30px;
        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }
  &.three-items,
  &.four-items {
    @media (orientation: landscape) {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 0;
    }
    .box {
      @media (orientation: portrait) {
        height: 24%;
      }

      @media (orientation: landscape) {
        height: calc(50% - 60px);
        width: calc(50% - 60px);
        margin: 30px;
        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }

      // @media (min-width: 2159px) {
      //   height: calc(50% - 40px);
      // }
    }
    .box-content {
      width: 100% !important;
    }
    .tiles {
      width: 50%;
      height: 100% !important;

      > div:nth-child(1) {
        width: 100%;
        @media (orientation: landscape) {
          width: calc(50% - 30px);
        }
      }
      > div:nth-child(2) {
        display: none;
      }
    }
    .chart {
      width: calc(50% - 15px);
      min-width: calc(50% - 15px);
      right: 0;
      top: 0;
      height: 100% !important;
      color: #444;
      background: white;
      @media (orientation: landscape) {
        height: 50%;
      }

      > .loading-tile {
        background: white !important;
        color: #444;
      }
    }

    .no-data-tile h3 {
      top: initial;
      bottom: 15px;
      width: 100%;
      text-align: center;
      @media (orientation: landscape) {
        font-size: 1.25rem;
      }
      @media (min-width: 2159px) {
        font-size: 2rem;
      }
    }

    .chart-forecast > .tile {
      .weatherIcon > div {
        font-size: 1.4em;
        right: 15px;
      }
    }

    @media (orientation: landscape) {
      .chart-forecast > .tile {
        padding: 15px !important;
        width: calc(100% - 30px) !important;
        height: calc(100% - 30px) !important;
        .weatherIcon > div {
          font-size: 1.2em;
          height: 150px;
        }
      }
    }
  }

  .box {
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    max-height: 50%;

    @media (orientation: landscape) {
      &:nth-child(4) {
        display: flex;
      }
    }
  }

  .box-content {
    // height: calc(100% - 75px);
    position: relative;

    @media (orientation: landscape) {
      width: 100%;
      .tiles {
        width: 100%;
      }
    }
    // @media (min-width: 2159px) {
    //   height: calc(100% - 80px);
    // }
    // @media (min-width: 2159px) {
    //   height: calc(100% - 120px);
    // }
    // @media (min-width: 2159px) and (orientation: landscape) {
    //   height: calc(100% - 105px);
    // }
  }

  .tiles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(50% - 15px) !important;
    width: 100%;
    > div {
      width: calc(50% - 40px);
      flex-grow: 0;
    }
  }

  .chart {
    position: absolute;
    height: 50%;
    min-height: 50%;
    width: 100%;
    min-width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    background: white;
    color: black;
    flex: 0;
    overflow: hidden;
  }

  .chart-forecast {
    height: 50% !important;
  }

  .chartContent {
    padding: 15px;
    width: calc(100% - 30px);
    height: auto;

    > div > div {
      flex-grow: 1;
    }
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 220px);
    .box {
      display: block;
      height: 50%;
      min-height: 50%;
      max-height: 50%;
      &:nth-child(4) {
        display: flex;
      }
    }
    .tiles {
      flex-direction: column;
      height: auto !important;
      width: 100% !important;
      > div {
        min-width: calc(100% - 30px) !important;
        width: calc(100% - 30px) !important;
        min-height: 250px;
        flex-grow: 1;
        &:nth-child(2) {
          display: flex !important;
          margin-top: 15px;
        }
      }
    }

    .box-content {
      height: auto;
      min-height: initial;
      max-height: initial;
      width: 100%;
    }
    .box {
      height: auto;
      min-height: initial;
      max-height: initial;
    }

    .box + .box {
      margin-top: 30px;
    }

    .box-content > div {
      height: auto;
    }

    .chart {
      position: relative;
      height: auto;
      min-height: initial;
      max-height: initial;
      min-height: 350px;
      width: 100% !important;
      margin-top: 15px;
      min-width: 100% !important;
      max-height: 500px;
    }

    .chart-forecast .tile {
      min-height: 300px;
    }
  }
}
