/*
You do not need to worry about .header {} colliding with any other `.css` or
`.module.css` files!
*/

@keyframes slowFade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.9;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 30px;
  right: 30px;
  height: 60px;
  background: rgb(0, 0, 0, 0.6) !important;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border-radius: 0 0 10px 10px;
  padding: 15px 35px 15px 35px;
  z-index: 3;
  animation-duration: 30s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-play-state: running;
  animation-iteration-count: infinite;
  animation-name: slowFade;

  @media (max-width: 1024px) {
    padding: 15px;
    left: 15px;
    right: 15px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    height: 40px;
  }

  @media (min-width: 2159px) {
    height: 120px;
  }

  .labels {
    flex: 1 0 auto;

    h1 {
      margin: 0;
      margin-bottom: 5px;
      font-weight: 300;
      font-size: 1.6rem;

      @media (min-width: 2159px) {
        font-size: 1.25vh;
        margin-top: 5px;
        margin-bottom: 10px;
      }
      @media (min-width: 2159px) and (orientation: landscape) {
        font-size: 1.25vw;
      }
    }
    p {
      color: #68ab0b;
      margin: 0;
      @media (min-width: 2159px) {
        font-size: 1vh;
      }
      @media (min-width: 2159px) and (orientation: landscape) {
        font-size: 0.9vw;
      }
    }
  }

  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: right;
    text-transform: uppercase;
    font-weight: bold;
    height: 60px;

    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 2159px) {
      height: 120px;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 0px;
      line-height: 1.3;
      @media (min-width: 2159px) {
        font-size: 0.9vh;
        margin-right: 10px;
      }
      @media (min-width: 2159px) and (orientation: landscape) {
        font-size: 0.7vw;
      }
    }
  }

  .clock {
    margin-left: 10px;
    justify-content: flex-end;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .logo {
    width: 200px;
    height: 62px;
    background: url("../../../public/images/logo-green.svg") no-repeat center center;
    background-size: contain;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    @media (max-width: 767px) {
      width: 100px;
      margin-left: -50px;
      height: 31px;
    }
    @media (min-width: 2159px) {
      height: 86px;
    }
  }
}
