.weatherTile {
  position: relative;

  .largeDoubleText {
    display: flex;
    font-weight: 400;

    justify-content: flex-end;
    sup {
      vertical-align: top;
      line-height: 1.6;
      font-size: 40%;
    }
  }

  .usageIcon {
    height: 50%;
    top: 50%;
    display: flex;
    position: absolute;
    left: 0px;
    margin-top: -22%;
  }

  .spacer {
    margin: 0 10px 0px 5px;
    font-weight: 100;
  }

  .largeDoubleText,
  .spacer {
    font-size: 6vw;
    @media (orientation: landscape) {
      font-size: 4vw;
    }
  }

  .mediumText {
    margin-top: 15px;
    text-align: right;
    font-size: 1.5vw;
    @media (orientation: landscape) {
      font-size: 0.9vw;
    }
  }

  .textContent {
    position: absolute;
    bottom: 24px;
    left: 24px;
    .largeText {
      display: block;
      font-size: 2.25vw;
      font-weight: 500;
      text-transform: capitalize;
      @media (orientation: landscape) {
        font-size: 1.25vw;
      }
    }
    .smallText {
      display: block;
      margin-top: 16px;
      font-size: 1.75vw;
      @media (orientation: landscape) {
        font-size: 0.75vw;
      }
    }
  }
}
