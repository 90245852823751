.noDataTile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  h3 {
    position: absolute;
    top: 15px;
    left: 25px;
    color: #444;
    font-weight: bolder;
    opacity: 0.8;
  }
}
