.forecastTile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px !important;
  width: calc(100% - 30px) !important;
  height: calc(100% - 30px) !important;
  @media (orientation: landscape) {
    padding: 30px 50px !important;
    width: calc(100% - 100px) !important;
    height: calc(100% - 60px) !important;
  }

  .location {
    width: 100%;
    font-size: 3vw;
    @media (orientation: landscape) {
      font-size: 2vw;
    }
    span {
      display: block;
      width: 100%;
      font-weight: normal;
      font-size: 1.5vw;
      text-transform: uppercase;
      margin-top: 10px;
      @media (orientation: landscape) {
        font-size: 1vw;
      }
    }
  }

  .currentWeather {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    .tempWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .largeTemperature {
      font-size: 8vw;
      @media (orientation: landscape) {
        font-size: 4vw;
      }
    }

    .currentWeatherDescription {
      margin-top: 10px;
      font-weight: 100;
      font-size: 1.8vw;
      @media (orientation: landscape) {
        font-size: 1vw;
      }
    }

    .weatherIcon {
      position: absolute;
      top: 15px;
      right: -50px;
      text-align: center;
      > div {
        font-size: 1.7em;
      }
      @media screen and (max-width: 768px) {
        top: 0px;
        right: 0px;
        > div {
          font-size: 0.8em;
        }
      }
      @media (orientation: landscape) {
        top: 15px;
        right: -10px;
      }
    }
  }

  .forecasts {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    height: 30%;
  }

  .forecastItem {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    text-align: center;
    color: #777;
  }

  .forecastItemIcon {
    width: auto;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    > div {
      font-size: 7px;
      @media (orientation: landscape) {
        font-size: 6px;
      }
    }
  }

  .forecastItemTemperature {
    font-size: 2vw;
    position: relative;
    z-index: 9;
    @media (orientation: landscape) {
      font-size: 1.2vw;
    }
  }

  .forecastItemDate {
    margin-top: 5px;
    font-size: 1.5vw;
    @media (orientation: landscape) {
      font-size: 0.8vw;
    }
  }
}
