.customPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: contain;
    border-radius: 10px;
    background-position: top center;
  }

  &.landscapeMulti {
    display: flex;
    > div {
      width: calc(50% - 48px);
      height: calc(50% - 48px);
      margin: 24px;
    }
  }

  &.portraitMulti {
    display: flex;
    > div {
      height: calc(33.3% - 48px);
      margin: 24px 0px;
    }
  }
  &.landscapeSingle {
    display: flex;
    > div {
    }
  }
  &.portraitSingle {
    display: flex;
    > div {
    }
  }
}
