.custom-cbre {
  border-radius: 10px;
  // padding: 20px 15px;
  overflow: hidden;
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  position: relative;
  h2 {
    margin: 0;
    margin-bottom: 24px;
    font-weight: 500;
    @media (min-width: 1024px) {
      font-size: 1.8vh;
      line-height: 2.2vh;
    }
    @media (orientation: landscape) {
      font-size: 3vh;
      line-height: 2.2;
    }
    @media (min-width: 2159px) {
      margin-bottom: 24px;
      // font-size: 1.5vh;
    }
  }
  p {
    margin-top: 26px;
    @media (min-width: 1024px) {
      font-size: 1.4vh;
      line-height: 1.8;
    }
    @media (orientation: landscape) {
      font-size: 2.05vh;
      line-height: 1.4;
    }
    @media (min-width: 2159px) {
      // line-height: 2.8vw;
      // font-size: 1.5vh;
    }
  }
}
