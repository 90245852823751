.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (orientation: landscape) {
    height: inherit;
  }

  .boxContent {
    @media (min-width: 1025px) {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
    }
    @media (orientation: landscape) {
      height: auto;
      flex-grow: 1;
      flex-shrink: 0;
      flex-direction: row;
    }

    @media (max-width: 1023px) {
      display: block;
      height: auto;
      flex-grow: 1;
      max-height: inherit;
    }

    > div {
      height: 50%;

      @media (orientation: landscape) {
        width: 50%;
        height: auto;
      }
      @media (max-width: 1024px) {
        height: auto;
        flex-grow: 1;
      }
    }
  }

  .title {
    position: absolute;
  }

  .rightOfTitle {
    position: absolute;
  }
}
