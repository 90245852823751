.usageTile {
  position: relative;

  svg {
    height: 100%;
    width: 100%;
  }
  .topRightContent {
    text-align: right;

    font-size: 6vw;
    text-align: right;
    @media (orientation: landscape) {
      font-size: 4vw;
    }

    span {
      font-size: 2.15vw;
      @media (orientation: landscape) {
        font-size: 0.75vw;
      }
    }
  }

  .date {
    font-size: 2.15vw;
    @media (orientation: landscape) {
      font-size: 1.25vw;
    }
  }

  .otherContent {
    .usageIcon {
      height: 50%;
      top: 50%;
      display: flex;
      position: absolute;
      left: 64px;
      margin-top: -22%;
    }

    .textContent {
      position: absolute;
      bottom: 24px;
      left: 24px;
      .largeText {
        display: block;
        font-size: 2.25vw;
        font-weight: 500;
        text-transform: capitalize;
        @media (orientation: landscape) {
          font-size: 1.25vw;
        }
      }
      .smallText {
        display: block;
        font-size: 1.75vw;
        margin-top: 4px;
        @media (min-width: 2159px) {
          margin-top: 16px;
        }
        @media (orientation: landscape) {
          font-size: 0.75vw;
        }
      }
    }
  }
  // .largePercentageSmallText {
  //   text-align: right;
  //   font-size: 1.5vw;
  //   @media (orientation: landscape) {
  //     font-size: 0.75vw;
  //   }
  // }
  // .largePercentage {
  //   font-size: 6vw;
  //   font-weight: bold;
  //   text-align: right;
  //   @media (orientation: landscape) {
  //     font-size: 3.25vw;
  //   }

  //   span {
  //     font-size: 50%;
  //   }
  // }

  // sup {
  //   vertical-align: top;
  //   line-height: 2;
  //   // font-size: 60%;
  // }

  // .usage {
  //   position: absolute;
  //   left: 15px;
  //   top: 20px;
  //   bottom: 20px;
  //   max-width: 250px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-end;
  //   text-align: left;
  // }

  // .usageBarIcon {
  //   position: absolute;
  //   left: 50%;
  //   margin-left: -12px;
  //   width: 24px;
  //   height: 60px;
  //   bottom: 20px;
  //   max-height: 25%;
  //   background-size: contain !important;
  //   z-index: 3;

  //   &.electricity {
  //     background: url('/images/electricity.svg') center center no-repeat;
  //   }
  //   &.gas {
  //     background: url('/images/gas.svg') center center no-repeat;
  //   }
  //   &.water {
  //     background: url('/images/water.svg') center center no-repeat;
  //   }
  //   // &.Weather {
  //   //   background: url('/images/icon-electricity.png') center center no-repeat;
  //   // }
  // }

  // .largeText {
  //   text-align: right;
  //   margin-right: 15px;
  //   padding-bottom: 10px;
  //   font-size: 2vw;
  //   line-height: 1.2;
  //   text-transform: capitalize;

  //   @media (orientation: landscape) {
  //     font-size: 1vw;
  //     line-height: 1.3;
  //   }
  //   @media (min-width: 2159px) {
  //     margin-right: 30px;
  //   }
  // }
}
