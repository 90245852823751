.equivalentTile {
  position: relative;

  .largeText {
    font-size: 6vw;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    text-align: right;
    position: absolute;
    right: 15px;
    top: 15px;
    span {
      font-size: 2vw;
      font-weight: normal;
    }
    label {
      font-weight: normal;
      font-size: 1.5vw;
    }
    @media (orientation: landscape) {
      font-size: 4vw;
      label {
        font-size: 0.75vw;
      }
      span {
        font-size: 1vw;
      }
    }
  }

  .textContent {
    position: absolute;
    bottom: 24px;
    left: 24px;
    .textContentA {
      display: block;
      font-size: 2.25vw;
      font-weight: 500;
      text-transform: capitalize;
      @media (orientation: landscape) {
        font-size: 1.25vw;
      }
    }
    .textContentB {
      display: block;
      margin-top: 16px;
      font-size: 1.75vw;
      @media (orientation: landscape) {
        font-size: 0.75vw;
      }
    }
  }

  .largeIcon {
    height: 50%;
    width: 100%;
    top: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: -22%;
  }

  // .largeIcon {
  //   position: absolute;
  //   top: 120px;
  //   left: 30px;
  //   bottom: 120px;
  //   right: 30px;

  //   // &.electricity,
  //   // &.gas {
  //   //   top: 220px;
  //   // }

  //   // @media (orientation: landscape) {
  //   //   top: 240px;
  //   //   &.electricity,
  //   //   &.gas {
  //   //     top: 240px;
  //   //   }
  //   // }
  //   svg {
  //     height: 100%;
  //     width: 100%;
  //   }
  // }

  .line {
    position: absolute;
    left: -40px;
    width: 20px;
    height: 2px;
    background: white;
    &.top {
      top: -2px;
    }
    &.bottom {
      bottom: 0;
    }
  }
}
