@keyframes bulb {
  0% {
    opacity: 0.8;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(0.8);
  }
}

@keyframes flame {
  0% {
    stroke: #ffdc01;
    transform: scale(0.9);
  }
  40% {
    stroke: #fdac01;
    transform: scale(1);
  }
  100% {
    stroke: #f73b01;
    transform: scale(0.9);
  }
}

@keyframes flame2 {
  0% {
    transform: scale(0.9);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes rain-animation {
  0% {
    opacity: 0.8;
    transform: translate3d(0, -30px, 0);
    // transform: scale(1);
  }

  25% {
    opacity: 0.5;
    transform: translate3d(0, 0px, 0);
    // transform: scale(1.1);
  }

  50% {
    transform: translate3d(0, 50px, 0);
    // transform: scale(1.1);
  }

  75% {
    opacity: 0.3;
    transform: translate3d(0, 80px, 0);
    // transform: scale(1.1);
  }

  100% {
    opacity: 0.2;
    transform: translate3d(0, 80px, 0);
    // transform: scale(1.1);
  }
}

@keyframes rain-animation-2 {
  0% {
    transform: translate3d(0, 0px, 0);
    // transform: scale(1);
  }

  25% {
    transform: translate3d(0, 0px, 0);
    // transform: scale(1.1);
  }

  100% {
    opacity: 0.3;
    transform: translate3d(0, 30px, 0);
    // transform: scale(1.1);
  }
}

@keyframes rain-animation-3 {
  0% {
    opacity: 1;
    transform: translate3d(0, -30px, 0);
    // transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: translate3d(0, 80px, 0);
    // transform: scale(1.1);
  }

  100% {
    opacity: 0.3;
    transform: translate3d(0, 80px, 0);
    // transform: scale(1.1);
  }
}

.hidden {
  visibility: hidden;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#wind-blade {
  width: 258.63px;
  height: 258.63px;
  transform-origin: 94.663px 94.663px;
  animation: spin 0s linear infinite;
  transform: translate3d(0, 0px, 0);
  transform: rotate(15deg);
}
