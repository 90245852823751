body .react-clock {
  .react-clock__face {
    border: none;
  }
  .react-clock__hand__body {
    background: white;
  }
  .react-clock__hour-mark__body {
    background-color: orange;
  }
}
