@import url("https://fonts.googleapis.com/css?family=Fira+Sans");

.not-found {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: "Fira Sans", sans-serif;

  .logo {
    width: 400px;
    max-width: 100%;
    height: 112px;
    background: url("../../../public/images/logo-green.svg") no-repeat center center;
    background-size: contain;
    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;
  }

  .left-section .inner-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#0c0e10, #446182);
  }
  .background .ground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25vh;
    background: #1e1f21;
  }
  @media (max-width: 1024px) {
    .background .ground {
      height: 0vh;
    }
  }

  .container {
    position: relative;
    margin: 0 auto;
    width: 85%;
    height: 100vh;
    padding-bottom: 25vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  @media (max-width: 770px) {
    .container {
      flex-direction: column;
      padding-bottom: 0vh;
    }
  }

  .left-section,
  .right-section {
    position: relative;
  }

  .left-section {
    width: 40%;
    @media (max-width: 770px) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
  }
  @media (max-width: 770px) {
    .left-section .inner-content {
      position: relative;
      padding: 1rem 0;
    }
  }

  .subheading {
    text-align: center;
    max-width: 480px;
    font-size: 1.5em;
    line-height: 1.5em;
    padding: 0 1rem;
    margin: 0 auto;
  }

  .right-section {
    width: 50%;
  }
  @media (max-width: 1024px) {
    .right-section {
      display: none;
      width: 100%;
      height: 60%;
      position: absolute;
      bottom: 0;
    }
  }

  .svgimg {
    position: absolute;
    bottom: 0;
    padding-top: 10vh;
    padding-left: 1vh;
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 770px) {
    .svgimg {
      padding: 0;
    }
  }
  .svgimg .bench-legs {
    fill: #0c0e10;
  }
  .svgimg .top-bench,
  .svgimg .bottom-bench {
    stroke: #0c0e10;
    stroke-width: 1px;
    fill: #5b3e2b;
  }
  .svgimg .bottom-bench path:nth-child(1) {
    fill: #432d20;
  }
  .svgimg .lamp-details {
    fill: #202425;
  }
  .svgimg .lamp-accent {
    fill: #2c3133;
  }
  .svgimg .lamp-bottom {
    fill: linear-gradient(#202425, #0c0e10);
  }
  .svgimg .lamp-light {
    fill: #efefef;
  }

  img {
    height: 80%;
  }
}
