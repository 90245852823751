@keyframes slowFade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.9;
  }
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 15px;
  left: 30px;
  right: 30px;
  z-index: 3;

  animation-duration: 30s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-play-state: running;
  animation-iteration-count: infinite;
  animation-name: slowFade;

  div {
    width: 50%;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;

    @media (min-width: 2159px) {
      height: 75px;
    }
    + div {
      background-position: right;
    }
  }
}
