.windTile {
  position: relative;

  .largeText {
    font-size: 6vw;
    display: flex;
    flex-direction: column;
    text-align: right;
    position: absolute;
    right: 15px;
    top: 15px;
    span {
      font-size: 2vw;
    }

    @media (orientation: landscape) {
      font-size: 4vw;
      span {
        font-size: 1vw;
      }
    }
  }

  h3 {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    @media (orientation: landscape) {
      font-size: 2rem;
    }
  }
  .windContainer {
    position: absolute;
    top: 80px;
    left: 0px;
    bottom: 90px;
    right: 0px;
    transition: 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20%;
      @media (orientation: landscape) {
        width: auto;
        height: 70%;
      }
    }
  }
}
