.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  animation: spinLoader 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spinLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fullscreen {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333;
  z-index: 15;
}

.content {
  text-align: center;
  margin-top: -100px;
  margin-bottom: 50px;
  max-width: 80%;
  p {
    font-size: 24px;
    line-height: 1.5;
  }
}

.logo {
  width: 400px;
  max-width: 100%;
  height: 112px;
  background: url("../../../public/images/logo-green.svg") no-repeat center center;
  background-size: contain;
  margin: 0 auto;
  position: relative;
  margin-bottom: 30px;
  transition:
    opacity 0.1s,
    transform 0.1s;
  @media screen and (max-width: 767px) {
    width: 300px;
  }
  &.pulse {
    animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  }
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}
