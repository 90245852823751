@keyframes slowFade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.9;
  }
}

/* mask and mask image */
.mask {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  animation-duration: 30s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-play-state: running;
  animation-iteration-count: infinite;
  animation-name: slowFade;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
}

.maskImage {
  flex: 1 1 auto;
  background-size: cover !important;
}
