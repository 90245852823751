.loadingTile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100%;

  h3 {
    position: absolute;
    bottom: 15px;
  }
}
