/*Core styles*/
html,
body,
#root {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
}

/*Text trimming*/
.text-trim {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* main tag header and footer and content sit between */
main {
  z-index: 3;
  position: absolute;
  top: 110px;
  left: 60px;
  right: 60px;
  bottom: 100px;

  // tablet and below
  @media (max-width: 1024px) {
    overflow: auto;
    left: 15px;
    right: 15px;
    bottom: 90px;
    .page {
      height: auto;
    }
  }
  // mobile and below
  @media (max-width: 767px) {
    top: 90px;
    bottom: 70px;
  }
  @media (min-width: 2159px) {
    top: 180px;
    bottom: 120px;
  }
}

/* page / slide tag for home page content */
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  @media (orientation: landscape) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

// general tile
.tile {
  border-radius: 10px;
  padding: 20px 15px;
  overflow: hidden;
  transition: opacity 0.25s ease-in-out;
  opacity: 1;
}

#lottie {
  position: absolute;
  top: 80px;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

#lightbulb {
  transform-origin: center center;
  animation: bulb 2s ease-in-out infinite;
  animation-direction: alternate;
  transform: translate3d(0, 0, 0);
}

#drops-1 {
  animation: rain-animation 0.75s linear infinite;
  transform: translate3d(0, 0, 0);
}

#drops-2 {
  transform-origin: center bottom;
  animation: rain-animation-2 0.75s linear infinite 0.1s;
  transform: translate3d(0, 0, 0);
}

#drops-3 {
  transform-origin: center bottom;
  animation: rain-animation-3 1s linear infinite 0.1s;
  transform: translate3d(0, 0, 0);
}

#flame {
  transform-origin: center center;
  animation: flame 1.3s linear infinite;
  animation-direction: alternate;
  transform: translate3d(0, 0, 0);
}

#flame2 {
  transform-origin: center center;
  animation: flame2 0.7s linear infinite;
  animation-direction: alternate;
  transform: translate3d(0, 0, 0);
}
.recharts-surface {
  overflow: visible;

  text {
    @media (min-width: 2159px) {
      font-size: 24px;
    }
  }
}
